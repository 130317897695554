//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'
import { APP_RATING, GOOGLE_PLAY_APP_LINK, APPLE_STORE_APP_LINK } from '~/constants'

export default defineComponent({
    name: 'IndApplication',
    setup () {
        const videoPlayer = ref(null)

        const applicationBlock = ref(null)

        onMounted(() => {
            new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0) {
                        if (videoPlayer.value) {
                            videoPlayer.value.play()
                            observer.disconnect()
                        }
                    }
                })
            }).observe(applicationBlock.value)
        })

        return {
            videoPlayer,
            applicationBlock,
            appRating: APP_RATING,
            googlePlayAppLink: GOOGLE_PLAY_APP_LINK,
            appleStoreAppLink: APPLE_STORE_APP_LINK
        }
    },
    computed: {
        ...mapGetters(['isMobile'])
    }
})
