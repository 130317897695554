//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import IndLiveReviews from '~/components/index/IndLiveReviews'
import IndReviews from '~/components/index/IndReviews'
import IndBlocks from '~/components/index/IndBlocks'
import IndAward from '~/components/index/IndAward'
import IndSoloAds from '~/components/index/IndSoloAds'
import IndIdVerifiedSellers from '~/components/index/IndIdVerifiedSellers'
import IndPersonalTouch from '~/components/index/IndPersonalTouch'
import IndWhatMakesUdimiSoloAdsBetter from '~/components/index/IndWhatMakesUdimiSoloAdsBetter'
import IndAffiliatesMakeMoreMoney from '~/components/index/IndAffiliatesMakeMoreMoney'
import IndApplication from '~/components/index/IndApplication'
import LayMobileFooter from '~/components/layouts/LayMobileFooter'
import LayDesktopFooter from '~/components/layouts/LayDesktopFooter'
import LayMobileHeader from '~/components/layouts/LayMobileHeader'
import LayMobileMenu from '~/components/layouts/LayMobileMenu'
import CoGreyBox from '~/components/common/CoGreyBox.vue'
import CoConsentUseSite from '~/components/common/CoConsentUseSite.vue'
import CoVideoPlayer from '~/components/common/CoVideoPlayer'
import IndTopSellers from '~/components/index/IndTopSellers'
import IndSafety from '~/components/index/IndSafety'
import LayDesktopHeader from '~/components/layouts/LayDesktopHeader'
import { TSOURCE_DIRECT, TSOURCE_JV } from '@/constants/tsource'
import { APP_RATING } from '~/constants'

export default {
    name: 'Index',
    components: {
        LayDesktopHeader,
        IndSafety,
        IndTopSellers,
        IndIdVerifiedSellers,
        IndPersonalTouch,
        IndWhatMakesUdimiSoloAdsBetter,
        IndAffiliatesMakeMoreMoney,
        CoVideoPlayer,
        LayMobileMenu,
        LayMobileHeader,
        LayMobileFooter,
        LayDesktopFooter,
        IndLiveReviews,
        IndReviews,
        IndBlocks,
        IndAward,
        IndSoloAds,
        IndApplication,
        CoGreyBox,
        CoConsentUseSite
    },
    layout: 'empty',
    middleware: 'auth_login',
    async asyncData ({ store, app, route, req, redirect }) {
        let affiliate
        let user
        if (process.server) {
            // if there is uid in url, try to set the cookie
            if (route.params.uid) {
                if (!(route.params.uid.length === 5 && /[a-zA-Z0-9]{5}/.test(route.params.uid))) {
                    return redirect(301, '/')
                }
                // get affiliate info
                ({ user, affiliate } = await app.$api.get('guests/landing/affiliate/' + route.params.uid)
                    .then(res => res.data)
                    .catch(err => app.$handleErrors(err)))

                // preview of own affiliate page
                if (+user?.id === +affiliate?.id) {
                    return { affiliate, user }
                }

                // set cookies from register affiliate click
                await store.dispatch('cookies/registerAffiliateClick', { user, affiliate, req, route })

                // capture jv click for guests only
                if (!user) {
                    await store.dispatch('cookies/setTsourceCookie', { uid: TSOURCE_JV })
                }
            } else if (!store.getters['cookies/auth'].id) {
                // regular visit for guests only
                await store.dispatch('cookies/setTsourceCookie', { uid: TSOURCE_DIRECT })
            }
        }

        // if cookie exists, but it's different from which was set
        const promo = store.getters['cookies/promo']
        const uid = promo?.uid
        if (uid && (!affiliate || affiliate.uid !== uid)) {
            ({ user, affiliate } = await app.$api.get('guests/landing/affiliate/' + uid)
                .then(res => res.data)
                .catch(err => app.$handleErrors(err)))
        }

        return { affiliate, user }
    },
    data: () => ({
        isMenu: false,
        appRating: APP_RATING,
        affiliate: null,
        user: null,
        isVideoPlaying: false,
        reviews: {},
        isFixedVideo: false,
        videoAutoplay: false,
        isMounted: false
    }),
    head () {
        const title = 'Buy solo ads from the best solo ad vendors - Udimi'

        return {
            titleTemplate: title,
            meta: [
                { name: 'og:title', property: 'og:title', hid: 'og:title', content: title },
                { name: 'twitter:title', hid: 'twitter:title', content: title },
                { name: 'description', hid: 'description', content: 'ID-verified sellers, top-quality traffic filter, unique rating system and custom tools will make your success inevitable!' },
                { name: 'og:description', property: 'og:description', hid: 'og:description', content: 'ID-verified sellers, top-quality traffic filter, unique rating system and custom tools will make your success inevitable!' },
                { name: 'twitter:description', hid: 'twitter:description', content: 'ID-verified sellers, top-quality traffic filter, unique rating system and custom tools will make your success inevitable!' },
                { name: 'keywords', hid: 'keywords', content: 'Buy solo ads, sell solo ads, buy email solo ad, email solo ads' }
            ],
            link: [
                { rel: 'canonical', hid: 'canonical', href: `${this.$config.siteProtocol}://${this.$config.siteHost}` },
                { rel: 'preload', fetchpriority: 'high', href: '/assets-outside/img/index_background/main-video-poster-sm.webp', as: 'image' }
            ]
        }
    },
    computed: {
        ...mapGetters(['isMobile']),
        loggedInWarning () {
            return this.affiliate && this.user && this.user.id !== this.affiliate.id
        },
        header () {
            return this.affiliate?.lander ? this.affiliate.lander.header : 'Solo ads. Buy top quality traffic from the best solo ad vendors'
        },
        subheader () {
            return this.affiliate?.lander?.subheader
        }
    },
    created () {
        if (!this.$device.isSafari) {
            this.videoAutoplay = true
        }
    },
    mounted () {
        this.isMounted = true
        if (!this.isMobile) {
            this.setVideoObserver()
        }
        if (this.$device.isSafari) {
            setTimeout(() => {
                this.videoAutoplay = true
                this.$nextTick(() => {
                    this.$refs.video?.startAutoplay()
                })
            }, 100)
        }
    },
    methods: {
        mainVideoPlay () {
            if (!this.isVideoPlaying) {
                this.isVideoPlaying = true
            }
        },
        mainVideoPause () {
            this.isVideoPlaying = false
        },
        menuToggle (val) {
            this.isMenu = val
        },
        setVideoObserver () {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    this.isFixedVideo = !entry.isIntersecting
                })
            })
            observer.observe(this.$refs.videoTopAnchor)
        },
        closeFixedVideo () {
            this.$refs.video?.$refs.videoPlayer?.pause()
            this.mainVideoPause()
        }
    }
}
